
































import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { HDHeaders } from './models/HDHeaders';

import InventoryAction from '@/pages/YMS/components/InventoryAction.vue';
import HDFilters from '@/pages/EDI/HD/HDFilters.vue';
import HDAction from '@/pages/EDI/HD/HDAction.vue';

@Component({
  components: { TmsTable, HDFilters, InventoryAction, HDAction }
})
export default class DailyInOut extends Vue {
  columns: TableHeaders[] = HDHeaders;
  inventory: any = {};

  get rows() {
    const a = [];
    for (let index = 0; index < 100; index++) {
      a.push({
        category: `caategory${index}`,
        date: '20210428000000',
        orderNumber: `aaaa${index}`,
        billTo: `bill to ${index}`,
        containerNumber: `number124 ${index}`
      });
    }
    return a;
  }

  constructor() {
    super();
  }

  openModal(a, b) {
    console.log(a, b);
    this.$bvModal.show('inventory-action-modal');
  }
}
